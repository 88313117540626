import { ref, onMounted, onBeforeUnmount } from 'vue';

const isMobile = ref(false);
const isLg = ref(false);

const updateSize = () => {
  isMobile.value = window.innerWidth <= 768;
  isLg.value = window.innerWidth >= 1024;
};

const useScreenSize = () => {
  onMounted(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateSize);
  });

  return {
    isMobile,
    isLg,
  };
};

export default useScreenSize;
